import validityEnum from '@/enums/validityEnum'

export default {
  data() {
    return {
      validityEnum,
    }
  },
  methods: {
    formatValidity(validityUnit, validity) {
      const parsedValidityUnit = parseInt(validityUnit, 10)
      const parsedValidity = parseInt(validity, 10)

      let billingIntervalName = ''

      switch (parsedValidityUnit) {
        case validityEnum.MINUTE:
          billingIntervalName = parsedValidity === 1 ? `${parsedValidity} Minute` : `${parsedValidity} Minutes`
          break
        case validityEnum.HOUR:
          billingIntervalName = parsedValidity === 1 ? `${parsedValidity} Hour` : `${parsedValidity} Hours`
          break
        case validityEnum.DAY:
          billingIntervalName = parsedValidity === 1 ? `${parsedValidity} Day` : `  ${parsedValidity} Days`
          break
        case validityEnum.WEEK:
          billingIntervalName = parsedValidity === 1 ? `${parsedValidity} Week` : `  ${parsedValidity} Weeks`
          break
        case validityEnum.MONTH:
          billingIntervalName = parsedValidity === 1 ? `${parsedValidity} Month` : `  ${parsedValidity} Months`
          break
        case validityEnum.YEAR:
          billingIntervalName = parsedValidity === 1 ? `${parsedValidity} Year` : `  ${parsedValidity} Years`
          break
        default:
          billingIntervalName = 'Invalid billing interval unit'
      }

      return billingIntervalName
    },
    formatBillingInterval(validityUnit, validity) {
      const parsedValidityUnit = parseInt(validityUnit, 10)
      const parsedValidity = parseInt(validity, 10)

      let billingIntervalName = ''

      switch (parsedValidityUnit) {
        case validityEnum.MINUTE:
          billingIntervalName = parsedValidity === 1 ? 'Per Minute' : `Every ${parsedValidity} Minutes`
          break
        case validityEnum.HOUR:
          billingIntervalName = parsedValidity === 1 ? 'Per Hour' : `Every ${parsedValidity} Hours`
          break
        case validityEnum.DAY:
          billingIntervalName = parsedValidity === 1 ? 'Per Day' : `Every ${parsedValidity} Days`
          break
        case validityEnum.WEEK:
          billingIntervalName = parsedValidity === 1 ? 'Per Week' : `Every ${parsedValidity} Weeks`
          break
        case validityEnum.MONTH:
          billingIntervalName = parsedValidity === 1 ? 'Per Month' : `Every ${parsedValidity} Months`
          break
        case validityEnum.YEAR:
          billingIntervalName = parsedValidity === 1 ? 'Per Year' : `Every ${parsedValidity} Years`
          break
        default:
          billingIntervalName = 'Invalid billing interval unit'
      }

      return billingIntervalName
    },
  },
}
