<template>
  <v-row justify="center">
    <v-dialog
      v-model="value"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="importData()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="form.import_from"
                    :items="importOptions"
                    item-text="name"
                    item-value="value"
                    label="Import from"
                    :rules="[v => !!v || 'Import from is required']"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-expand-transition>
                    <v-select
                      v-if="form.import_from === 'router'"
                      v-model="form.router_id"
                      :items="displayRouters"
                      item-text="displayText"
                      item-value="id"
                      label="Select a router"
                      :rules="[v => !!v || 'Router is required']"
                      :loading="isRoutersLoading"
                      outlined
                    ></v-select>
                    <v-select
                      v-if="form.import_from === 'station'"
                      v-model="form.station_id"
                      :items="displayStations"
                      item-text="displayText"
                      item-value="id"
                      label="Select a station"
                      :rules="[v => !!v || 'Station is required']"
                      :loading="isStationsLoading"
                      outlined
                    ></v-select>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Import
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isRoutersLoading: false,
    isStationsLoading: false,
    form: new Form({
      import_from: '',
      router_id: '',
      station_id: '',
      current_station_id: '',
    }),
    routers: [],
    stations: [],
    importOptions: [
      { name: 'From Router', value: 'router' },
      { name: 'From Another Station', value: 'station' },
    ],
  }),
  computed: {
    ...mapGetters(['selectedStation']),
    displayRouters() {
      return this.routers.map(router => ({
        ...router,
        displayText: `${router.station.name} Router`,
      }))
    },
    displayStations() {
      return this.stations
        .filter(station => (this.selectedStation ? station.id !== this.selectedStation : true))
        .map(station => ({
          ...station,
          displayText: `${station.name}`,
        }))
    },
  },
  watch: {
    'form.import_from': function (newVal) {
      if (newVal === 'router') {
        this.fetchRouters()
      } else if (newVal === 'station') {
        this.fetchStations()
      }
    },
    selectedStation(newVal) {
      this.form.current_station_id = newVal
    },
  },
  mounted() {
    this.form.current_station_id = this.selectedStation
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
      this.form.reset()
    },
    importData() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post(this.url)
          .then(response => {
            this.$toast.success('Imported successfully')
            this.$emit('imported', false)
            this.form.reset()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
    fetchRouters() {
      this.isRoutersLoading = true
      axios.get('/routers?perPage=all')
        .then(response => {
          this.routers = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isRoutersLoading = false
        })
    },
    fetchStations() {
      this.isStationsLoading = true
      axios.get('/stations?perPage=all')
        .then(response => {
          this.stations = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isStationsLoading = false
        })
    },
  },
}
</script>
