<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="550px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Price for {{ internetPlan.name }} plan</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="savePrice"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.price"
                    label="Price"
                    placeholder="Enter price"
                    :rules="[v => !!v || 'Price is required']"
                    :error="form.errors.has('price')"
                    :error-messages="form.errors.get('price')"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="selectedStation === 'all'"
                  cols="12"
                >
                  <v-select
                    v-model="form.station_id"
                    label="Station"
                    :items="stations"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    :loading="isStationsLoading"
                    :error="form.errors.has('station_id')"
                    :error-messages="form.errors.get('station_id')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    internetPlan: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      stations: [],
      isStationsLoading: false,
      form: new Form({
        price: '',
        station_id: '',
      }),
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    internetPlan(value) {
      if (Object.keys(value).length > 0) {
        this.form.update(value)
        this.form.price = ''
      }
    },
    selectedStation: {
      immediate: true,
      handler(newStation) {
        if (newStation === 'all' && this.stations.length === 0) {
          this.fetchStations()
        } else {
          this.form.station_id = newStation
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.reset()
      if (this.selectedStation !== 'all') {
        this.form.station_id = this.selectedStation
      }
    },
    fetchStations() {
      axios.get('stations', {
        params: {
          perPage: 'all',
        },
      })
        .then(response => {
          this.stations = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    savePrice() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .put(`internet-plans/${this.form.id}`)
          .then(response => {
            this.$toast.success('Internet Plan price updated successfully')
            this.$emit('close')
            this.$emit('internet-plan-price-added', response.data)
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
