<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Internet Plan</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pd-0"
                >
                  <v-text-field
                    v-model="form.name"
                    label="Name"
                    :placeholder="nameFieldPlaceholder"
                    :rules="[
                      v => !!v || 'Name is required',
                    ]"
                    required
                    :error="form.errors.has('name')"
                    :error-messages="form.errors.get('name')"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="internetConnectionType === internetConnectionTypeEnum.HOTSPOT"
                  cols="12"
                  class="pd-0"
                >
                  <v-textarea
                    v-model="form.description"
                    label="Description(Optional)"
                    placeholder="e.g unlimited internet for 24 hours"
                    :error="form.errors.has('description')"
                    :error-messages="form.errors.get('description')"
                    outlined
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col
                  v-if="internetConnectionType === internetConnectionTypeEnum.HOTSPOT"
                  cols="12"
                  class="mt-1 pt-1"
                >
                  <v-row
                    no-gutters
                    align="center"
                    class="mb-4"
                  >
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="form.has_speed_limit"
                        label="Set speed limit"
                        class="mt-0"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-tooltip
                        max-width="300"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="primary"
                            class="ml-2"
                            v-bind="attrs"
                            style="cursor: pointer;"
                            v-on="on"
                          >
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </template>
                        <span>By checking this box, you can define download and upload speed limits</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-expand-transition>
                  <v-col
                    v-if="form.has_speed_limit"
                    cols="12"
                  >
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="form.download_speed"
                          label="Download Speed"
                          placeholder="e.g 1"
                          required
                          :error="form.errors.has('download_speed')"
                          :error-messages="form.errors.get('download_speed')"
                          outlined
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          :key="'download_speed_unit_' + form.random_id"
                          v-model="form.download_speed_unit"
                          label="Download Speed Unit"
                          :items="speedUnitOptions"
                          item-text="name"
                          item-value="value"
                          :error="form.errors.has('download_speed_unit')"
                          :error-messages="form.errors.get('download_speed_unit')"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>
                <v-expand-transition>
                  <v-col
                    v-if="form.has_speed_limit"
                    cols="12"
                  >
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="form.upload_speed"
                          label="Upload Speed"
                          placeholder="e.g 1"
                          required
                          :error="form.errors.has('upload_speed')"
                          :error-messages="form.errors.get('upload_speed')"
                          outlined
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          :key="'upload_speed_unit_' + form.random_id"
                          v-model="form.upload_speed_unit"
                          label="Upload Speed Unit"
                          :items="speedUnitOptions"
                          item-text="name"
                          item-value="value"
                          :error="form.errors.has('upload_speed_unit')"
                          :error-messages="form.errors.get('upload_speed_unit')"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>
                <v-col
                  v-if="internetConnectionType === internetConnectionTypeEnum.HOTSPOT"
                  cols="12"
                  class="mt-1 pt-1"
                >
                  <v-row
                    no-gutters
                    align="center"
                    class="mb-4"
                  >
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="form.has_data_limit"
                        label="Set data limit"
                        class="mt-0"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-tooltip
                        max-width="300"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="primary"
                            class="ml-2"
                            v-bind="attrs"
                            style="cursor: pointer;"
                            v-on="on"
                          >
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </template>
                        <span>By checking this box, you can define the data limit. This limits the maximum data users can consume</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-expand-transition>
                  <v-col
                    v-if="form.has_data_limit"
                    cols="12"
                  >
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="form.data_limit"
                          label="Data Limit"
                          placeholder="e.g 1"
                          :rules="[]"
                          required
                          :error="form.errors.has('data_limit')"
                          :error-messages="form.errors.get('data_limit')"
                          outlined
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          :key="'data_limit_unit_' + form.random_id"
                          v-model="form.data_limit_unit"
                          label="Data Limit Unit"
                          :items="dataLimitUnitOptions"
                          item-text="name"
                          item-value="value"
                          :rules="[
                            v => (v !== null && v !== '') || 'Data limit unit is required',
                          ]"
                          :error="form.errors.has('data_limit_unit')"
                          :error-messages="form.errors.get('data_limit_unit')"
                          :compare="compareItems"
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.validity"
                        label="Validity"
                        placeholder="e.g 1"
                        :rules="[
                          v => (v !== null && v !== '') || 'Validity is required',
                        ]"
                        :error="form.errors.has('validity')"
                        :error-messages="form.errors.get('validity')"
                        outlined
                        type="number"
                        step="1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :key="'validity_unit' + form.random_id"
                        v-model="form.validity_unit"
                        label="Validity Unit"
                        :items="ValidityUnitOptions"
                        item-text="name"
                        item-value="value"
                        :rules="[
                          v => (v !== null && v !== '') || 'Validity unit is required',
                        ]"
                        :error="form.errors.has('validity_unit')"
                        :error-messages="form.errors.get('validity_unit')"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="internetConnectionType === internetConnectionTypeEnum.HOTSPOT"
                  cols="12"
                >
                  <v-text-field
                    v-model="form.number_of_shared_users"
                    label="Number of Shared Users"
                    type="number"
                    :rules="[
                      v => !v || v > 0 || 'Must be a positive number',
                    ]"
                    placeholder="Enter number of shared users"
                    hint="Specify the maximum number of users that can share this plan. Leave blank for unlimited."
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.price"
                        label="Price"
                        placeholder="e.g 1000"
                        :error="form.errors.has('price')"
                        :error-messages="form.errors.get('price')"
                        :compare="compareItems"
                        hint="Input numbers only, no commas"
                        outlined
                        type="number"
                        step="any"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="selectedStation === 'all'"
                  cols="12"
                >
                  <v-select
                    v-model="form.station_id"
                    label="Station"
                    :items="stations"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    :loading="isStationsLoading"
                    :error="form.errors.has('station_id')"
                    :error-messages="form.errors.get('station_id')"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="(internetConnectionType === internetConnectionTypeEnum.PPPOE || internetConnectionType === internetConnectionTypeEnum.HOTSPOT || internetConnectionType === internetConnectionTypeEnum.DYNAMIC)"
                  v-show="!(selectedStation === 'all' && form.station_id === '')"
                  cols="12"
                >
                  <v-select
                    v-model="form.ip_pool_id"
                    :items="ipPools"
                    item-text="name"
                    item-value="id"
                    label="Ip Pool"
                    :rules="[v => !!v || 'Ip Pool is required']"
                    :error="form.errors.has('ip_pool_id')"
                    :error-messages="form.errors.get('ip_pool_id')"
                    :loading="isIpPoolsLoading"
                    outlined
                  ></v-select>
                  <span v-if="!isIpPoolsLoading && ipPools.length === 0">
                    There are no any Ip Pools available. Please go to <router-link to="/ip-pools">Ip Pools</router-link> page and create one.
                  </span>
                </v-col>
                <v-col
                  v-if="internetConnectionType === internetConnectionTypeEnum.DYNAMIC && form.ip_pool_id && form.ip_pool_id !== ''"
                  cols="12"
                >
                  <v-select
                    v-model="form.dummy_ip_pool_id"
                    :items="ipPools.filter(pool => pool.id !== form.ip_pool_id)"
                    item-text="name"
                    item-value="id"
                    label="Dummy Ip Pool"
                    :rules="[v => !!v || 'Dummy Ip Pool is required', v => form.ip_pool_id !== form.dummy_ip_pool_id || 'Dummy IP Pool cannot be the same as IP Pool']"
                    :error="form.errors.has('dummy_ip_pool_id')"
                    :error-messages="form.errors.get('dummy_ip_pool_id')"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiInformationOutline } from '@mdi/js'
import Form from 'vform'
import { mapGetters } from 'vuex'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    plan: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      internetConnectionType: parseInt(this.$route.params.internet_connection_type, 10),
      stations: [],
      isStationsLoading: false,
      isIpPoolsLoading: false,
      internetConnectionTypeEnum,
      form: new Form({
        internet_connection_type: parseInt(this.$route.params.internet_connection_type, 10),
        name: '',
        description: '',
        download_speed: '',
        download_speed_unit: 1,
        upload_speed: '',
        upload_speed_unit: 1,
        has_data_limit: false,
        data_limit: null,
        data_limit_unit: 2,
        has_time_limit: false,
        time_limit: null,
        time_limit_unit: 2,
        number_of_shared_users: null,
        price: '',
        validity: '',
        validity_unit: 3,
        ip_pool_id: '',
        dummy_ip_pool_id: '',
        random_id: '',
        id: '',
        station_id: '',
        has_speed_limit: true,
      }),
      action: 'Save',
      speedUnitOptions: [
        { name: 'KBPS', value: 0 },
        { name: 'MBPS', value: 1 },
        { name: 'GBPS', value: 2 },
      ],
      dataLimitUnitOptions: [
        { name: 'KB', value: 0 },
        { name: 'MB', value: 1 },
        { name: 'GB', value: 2 },
        { name: 'TB', value: 3 },
      ],
      ValidityUnitOptions: [
        { name: 'MINUTE', value: 5 },
        { name: 'HOUR', value: 0 },
        { name: 'DAY', value: 1 },
        { name: 'WEEK', value: 2 },
        { name: 'MONTH', value: 3 },
        { name: 'YEAR', value: 4 },
      ],
      ipPools: [],
      icons: {
        mdiInformationOutline,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    nameFieldPlaceholder() {
      return this.internetConnectionType === this.internetConnectionTypeEnum.HOTSPOT ? 'e.g 1 hour or 1GB for 1 hour' : 'e.g 5Mbps'
    },
  },
  watch: {
    plan() {
      if (Object.keys(this.plan).length > 0 && this.showDialog) {
        this.form.update(this.plan)
        this.form.station_id = this.plan.router.station.id
        this.form.download_speed_unit = parseInt(this.plan.download_speed_unit, 10)
        this.form.upload_speed_unit = parseInt(this.plan.upload_speed_unit, 10)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    'form.name': function (value) {
      if (value === '' || value === null || value === undefined) {
        return
      }
      if (this.action === 'Update') {
        return
      }
      const match = this.form.name.match(/\d+/)
      if (match && this.has_speed_limit) {
        this.form.download_speed = Number(match[0])
        this.form.upload_speed = Number(match[0])
      }
    },
    selectedStation(newStation) {
      if (newStation === 'all' && this.stations.length === 0) {
        this.fetchStations()
      } else {
        this.form.station_id = this.selectedStation
        this.fetchIpPools()
      }
    },
    'form.station_id': function (newStationId) {
      if (newStationId && newStationId.trim() !== '') {
        this.fetchIpPools()
      }
    },
  },
  mounted() {
    this.initializeStation()
    this.initializeInternetType()
  },
  methods: {
    initializeStation() {
      if (this.selectedStation === 'all') {
        this.fetchStations()
      } else if (this.action === 'Save') {
        this.form.station_id = this.selectedStation
      }
    },
    initializeInternetType() {
      if (this.internetConnectionType === internetConnectionTypeEnum.HOTSPOT) {
        this.form.has_speed_limit = false
        this.form.validity_unit = 0
      }
    },
    fetchIpPools() {
      this.isIpPoolsLoading = true
      axios.get('ip-pools', {
        params: {
          stationId: this.form.station_id,
          perPage: 'all',
        },
      })
        .then(response => {
          this.ipPools = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isIpPoolsLoading = false
        })
    },
    fetchStations() {
      axios.get('stations', {
        params: {
          perPage: 'all',
        },
      })
        .then(response => {
          this.stations = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.errors.clear()
      this.$refs.form.reset()
      this.form.download_speed_unit = 1
      this.form.upload_speed_unit = 1
      this.form.data_limit_unit = 2
      this.form.validity_unit = 3
      this.form.station_id = ''
      this.form.ip_pool_id = ''
      this.form.has_data_limit = false
      this.form.has_speed_limit = true
      if (this.internetConnectionType === internetConnectionTypeEnum.HOTSPOT) {
        this.form.has_speed_limit = false
      } else {
        this.form.has_speed_limit = true
      }
      this.$nextTick(() => {
        this.form.random_id = Date.now()
      })
      this.initializeInternetType()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.savePlan()
        } else {
          this.updatePlan()
        }
      }
    },
    savePlan() {
      this.form
        .post('internet-plans')
        .then(response => {
          this.$toast.success('Internet Plan added successfully')
          this.$emit('close')
          this.$emit('internet-plan-added', response.data)
          this.closeDialog()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    updatePlan() {
      this.form
        .put(`internet-plans/${this.form.id}`)
        .then(response => {
          this.$toast.success('Internet Plan updated successfully')
          this.$emit('close')
          this.$emit('internet-plan-updated', response.data)
          this.closeDialog()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    compareItems(item1, item2) {
      return String(item1) === String(item2)
    },
  },
}
</script>
